<template>
  <div class="match-group-list">
    <div
      v-if="matchGroup.items.length"
      class="match-group-list__table mb-12"
    >
      <div v-if="!matchGroup.hideName" class="title-group-table d-flex align-center px-3 mx-0">
        <h4>{{ matchGroup.name }}</h4>
      </div>
      <v-data-table
        v-if="matchGroup.items.length"
        :headers="headers"
        :hide-default-footer="true"
        :items="matchGroup.items"
        item-key="uniqueId"
        :items-per-page="-1" dense
        :item-class="item => item.lastOfRound ? 'end-round' : null"
        :search="search"
      >
        <template #[`item.editAction`]="{ item }">
          <v-icon
            color="primary"
            @click="$router.push({ name: `match-edit`, params: { matchId: item.id, cohortId: item.cohortId } })"
            v-text="'create'"
          />
        </template>
        <template #[`item.status`]="{ item }">
          <div class="d-flex align-center">
            <v-tooltip top>
              <template #activator="{ on }">
                <span
                  class="status-icon"
                  :style="{ 'background-color': matchStatuses.find(s => s.id === item.status).color }" v-on="on"
                />
              </template>
              <span>{{ $t(`project.competition.match.${item.status}`) }}</span>
            </v-tooltip>
          </div>
        </template>
        <template #[`item.name`]="{ item }"><span class="text-no-wrap" v-text="item.name" /></template>
        <template #[`item.team0Name`]="{ item }">
          <div class="d-flex align-center">
            <avatar-field :value="item.team0Logo" :active="false" :is-square="true" custom-size="40" custom-icon="none" />
            <div class="match-group-list__name-team ml-3">
              <span v-text="item.team0Name" />
            </div>
          </div>
        </template>
        <template #[`item.team1Name`]="{ item }">
          <div class="d-flex align-center">
            <avatar-field :value="item.team1Logo" :active="false" :is-square="true" custom-size="40" custom-icon="none" />
            <div class="match-group-list__name-team ml-3">
              <span v-text="item.team1Name" />
            </div>
          </div>
        </template>
        <template #item.score="{ item }">
          <div class="d-flex align-center">
            <span v-if="item.score" class="text-no-wrap">{{ item.score }}</span>
            <span v-else v-text="'-'" />
          </div>
        </template>
        <template #[`item.locationName`]="{ item }">
          <div class="d-flex justify-space-between align-center">
            <v-tooltip top color="white">
              <template #activator="{ on }">
                <span class="text-truncate text-caption" style="max-width: 10rem;" v-on="on" v-text="item.locationName" />
              </template>
              <div class="tooltip-text pa-2" v-text="item.locationName" />
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'
import matchStatuses from '@/modules/competition/matches/matchStatuses'
import matchesAndRoundsMixin from '@/modules/competition/matches/matchesAndRoundsMixin'

export default {
  name: 'MatchGroupList',
  components: {
    AvatarField: () => import('@/components/AvatarField'),
  },
  mixins: [matchesAndRoundsMixin],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    matchGroup: { type: Object, required: true },
    showCategory: { type: Boolean, default: false },
    showLocation: { type: Boolean, default: false },
    search: { type: String, required: true },
  },
  data() {
    return {
      openErrorScoreDialog: false,
      selectedMatch: null,
      matchStatuses,
    }
  },
  computed: {
    ...mapGetters('organization', ['isBasketball']),
    ...mapGetters({ project: 'project/data' }),
    ...mapGetters('project', ['hasMatchLocationsFeature', 'isUserSubmanager']),
    headers: ({ hasMatchLocationsFeature, showCategory, showLocation }) => [
      { text: '', value: 'editAction', width: '3%' },
      { text: '', value: 'status', width: '3%' },
      ...(showCategory ? [{ text: i18n.t('common.category'), value: 'cohort.name' }] : []),
      { text: i18n.t('project.competition.headers.game'), value: 'shortName', cellClass: 'text-no-wrap' },
      { text: `${i18n.t('common.team')} 1`, value: 'team0Name' },
      { text: `${i18n.t('common.team')} 2`, value: 'team1Name' },
      { text: i18n.t('project.competition.headers.result'), value: 'score' },
      { text: i18n.t('common.date'), value: 'dateStr', cellClass: 'text-no-wrap' },
      ...(showLocation ? [{
        text: hasMatchLocationsFeature ? i18n.t('project.competition.headers.field') : i18n.t('project.competition.headers.situation'),
        value: 'locationName',
      }] : []),
    ],
  },
  methods: {
  },
}
</script>

<style lang="scss">
.match-group-list {

  span {
    font-size: 82%;
  }

  .v-data-table {
    &__wrapper {
      tr {
        &.end-round {
          td {
            @media(min-width: 768px) {
              border-bottom: 4px solid rgba(0, 0, 0, 0.12) !important;
            }
            &:last-child {
              @media(max-width: 767px) {
                border-bottom: 4px solid rgba(0, 0, 0, 0.12) !important;
              }
            }
          }
        }
        td {
          height: 5rem !important;
        }
      }
    }
  }
  .status-icon {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    filter: blur(2px);
  }
}

.v-data-table__mobile-table-row .v-data-table__mobile-row:nth-child(2) .v-data-table__mobile-row__cell,
.v-data-table__mobile-table-row .v-data-table__mobile-row:nth-child(3) .v-data-table__mobile-row__cell {
  width: 100%;
}
</style>
