import { render, staticRenderFns } from "./MatchGroupList.vue?vue&type=template&id=5a7c91d7&"
import script from "./MatchGroupList.vue?vue&type=script&lang=js&"
export * from "./MatchGroupList.vue?vue&type=script&lang=js&"
import style0 from "./MatchGroupList.vue?vue&type=style&index=0&id=5a7c91d7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports